<template>
  <dash-page-new :title="selectedBusiness.name"
             :mobile-title="selectedBusiness.name"
             icon="mdi-view-dashboard-outline"
             no-side
              >

    <template #header_action>
      <v-btn
          :to="businessPublicLink('')"
          class="noCaps hoverUnderline"
          target="_blank"
          text
          :color="wsACCENT" >
        <v-icon class="mr-2">mdi-link</v-icon>
        {{ selectedBusiness.domain ? selectedBusiness.domain : ((is_westudy ? $store.state.second_hostname : $store.state.hostname ) + '/' + selectedBusiness.alias) }}
      </v-btn>
    </template>



    <template #default>

      <!-- :: QUICK STATS PANEL :: -->
      <v-sheet dark class="wsRoundedHalf mt-3 py-3 px-6"
               :color="wsDARK" style="background: linear-gradient(25deg, rgba(47, 84, 152, 0.76), rgba(157, 60, 0, 0.57))">
        <v-row class="pa-0 ma-0">
          <v-col cols="4" xs="4" sm="4" md="2" lg="2" xl="2" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">{{ $t('ActiveCourses') }}</h6>
            <h3 class="text-center">{{ dashboard.total_courses }}</h3>
          </v-col>
          <v-col cols="4" xs="4" sm="4" md="2" lg="2" xl="2" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">{{ $t('ActiveStudents') }}</h6>
            <h3 class="text-center">{{ dashboard.total_students }}</h3>
          </v-col>
          <v-col cols="4" xs="4" sm="4" md="2" lg="2" xl="2" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">{{ $t('ActiveNewLeads') }}</h6>
            <h3 class="text-center">{{ dashboard.total_leads }}</h3>
          </v-col>
          <v-col cols="4" xs="4" sm="4" md="2" lg="2" xl="2" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">{{ $t('NewStudents') }}</h6>
            <h3 class="text-center">
              <span v-if="dashboard.new_students > 0">+</span>
              {{ dashboard.new_students }}
            </h3>
          </v-col>
          <v-col cols="4" xs="4" sm="4" md="2" lg="2" xl="2" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">{{ $t('NewLeads') }}</h6>
            <h3 class="text-center">
              <span v-if="dashboard.new_leads > 0">+</span>
              {{ dashboard.new_leads }}
            </h3>
          </v-col>
          <v-col cols="4" xs="4" sm="4" md="2" lg="2" xl="2" class=" pa-0 ma-0 align-center ">
            <h6 class="text-center">{{ $t('MonthIncome') }}</h6>
            <h3 class="text-center">{{ dashboard.month_income }}<span v-if="dashboard.month_income > 0">k {{ BUSINESS_CURRENCY }}</span>  </h3>
          </v-col>
        </v-row>
      </v-sheet>

      <!-- :: COURSES AND STUDENTS :: -->
      <v-row class="my-3">
        <!-- Courses -->
        <v-col  cols="12" xs="12" sm="12" md="12" lg="8" xl="8"  >
          <v-sheet :max-height="!SM ? 300 : null" min-height="300" class="wsRoundedHalf pa-6 fill-height "
                   :color="courses.length === 0 ? wsACCENT : 'white'"
                   :dark="courses.length === 0 "
                   ref="coursesView"

          >

            <!-- Courses Table -->
            <div v-if="courses.length > 0">
              <div class="d-flex justify-space-between align-center">
                <h4 class="">Курси:</h4>
                <v-btn :to="businessDashLink('courses')" elevation="0" small  class="noCaps" outlined :color="wsACCENT">{{ $t('AllCourses')}}</v-btn>
              </div>
              <v-simple-table class="mt-5" max-height="300" >
                <tbody>
                <tr v-for="(item,i) in courses" :key="i">
                  <td class="py-3">
                    <div class="d-flex">
                      <v-avatar :color="wsBACKGROUND"  size="32" class="mr-3">
                        <v-icon dark>mdi-school</v-icon>
                      </v-avatar>
                      <div>
                        <h5>{{ item.name }}</h5>
                        <h6 class="font-weight-light">{{ item.name }}</h6>
                      </div>
                    </div>
                  </td>
                  <td v-if="!SM" width="10px">
                    <ws-chip :text="$t('Students') +': ' +  item.students_count"
                             :color="item.students_count > 0 ? wsACCENT : 'grey'"
                             icon="mdi-account-school" no-wrap />
                  </td>
                  <td width="10px">
                    <v-btn :to="businessDashLink('courses/' + item.uuid + '/editor')"  icon>
                      <v-icon :color="wsATTENTION">mdi-chevron-right-circle</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </div>
            <!-- No Courses -->
            <div v-else class="d-flex align-center justify-center fill-height">
              <div>
                <div class="d-flex justify-center">
                  <v-icon size="82">mdi-school</v-icon>
                </div>
                <h5 class="font-weight-light text-center mt-3">{{  $t('DashNoCoursesYet')  }}</h5>
                <v-btn :to="businessDashLink('courses')" outlined class="noCaps mt-3" block>
                  {{ $t('CoursesManagement') }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>

          </v-sheet>
        </v-col>
        <!-- Month Students Graphic -->
        <v-col  cols="12" xs="12" sm="12" md="12" lg="4" xl="4"  >
          <v-sheet class="wsRoundedHalf pa-6 fill-height"  max-height="300" min-height="300"
                   :color="dashboard.month_students_count > 0 ? 'white' : wsACCENT"
                   :dark="dashboard.month_students_count === 0">

            <div v-if="dashboard.month_students_count > 0">
              <div class="d-flex justify-space-between align-center">
                <h4 class="">Студентів за місяць:</h4>
                <v-chip outlined :color="wsACCENT" class="px-4">
                  <h3>
                    <span v-if="dashboard.month_students_count > 0">+</span>
                    {{ dashboard.month_students_count ? dashboard.month_students_count : 0 }}
                  </h3>
                </v-chip>
              </div>
              <v-sheet color="transparent"  >
                <apexchart class="ml-n1" type="line" height="230"   :options="chartOptionsStudent" :series="seriesStudent"></apexchart>
              </v-sheet>
            </div>
            <div v-else class="d-flex align-center justify-center fill-height">
              <div>
                <div class="d-flex justify-center">
                  <v-icon size="82">mdi-account-school</v-icon>
                </div>
                <h5 class="font-weight-light text-center mt-3">{{ $t('DashNoMonthStudentsYet') }}</h5>
              </div>
            </div>

          </v-sheet>
        </v-col>
      </v-row>

      <!-- :: LEADS :: -->
      <v-row class="mt-n3" >

        <!-- Leads Statistics -->
        <v-col v-if="dashboard.month_leads_count > 0"  cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
          <v-sheet class="wsRoundedHalf pa-6 fill-height " >
            <div class="d-flex justify-space-between align-center mb-6">
              <h4 class="">{{ $t('LeadsStatistics') }}:</h4>
              <v-btn elevation="0" small  class="noCaps" outlined :color="wsACCENT">Всі курси</v-btn>
            </div>

            <div class="d-flex justify-space-between mt-8">

              <div>
                <h6 class="font-weight-light">{{ $t('lead_new')}}</h6>
                <h4>{{ statisticsLeads.new ? statisticsLeads.new : 0 }} </h4>
              </div>

              <v-avatar :color="wsBACKGROUND"  size="32" >
                <v-icon dark small>mdi-card-account-phone-outline</v-icon>
              </v-avatar>

            </div>

            <div class="d-flex justify-space-between mt-3">

              <div>
                <h6 class="font-weight-light">{{ $t('lead_in_process')}}</h6>
                <h4>{{ statisticsLeads.in_process ? statisticsLeads.in_process : 0 }} </h4>
              </div>

              <v-avatar :color="wsBACKGROUND"  size="32" >
                <v-icon dark small>mdi-card-account-phone-outline</v-icon>
              </v-avatar>

            </div>

            <div class="d-flex justify-space-between mt-3">

              <div>
                <h6 class="font-weight-light">{{ $t('lead_success')}}</h6>
                <h4>{{ statisticsLeads.success ? statisticsLeads.success : 0 }} </h4>
              </div>

              <v-avatar color="green lighten-4"  size="32" >
                <v-icon dark small>mdi-card-account-phone-outline</v-icon>
              </v-avatar>

            </div>

            <div class="d-flex justify-space-between mt-3">

              <div>
                <h6 class="font-weight-light">{{ $t('lead_refuse')}}</h6>
                <h4>{{ statisticsLeads.refuse ? statisticsLeads.refuse : 0 }} </h4>
              </div>

              <v-avatar color="red lighten-4"  size="32" >
                <v-icon small dark>mdi-card-account-phone-outline</v-icon>
              </v-avatar>

            </div>

          </v-sheet>
        </v-col>
        <!-- Recent Leads Statistics -->
        <v-col v-if="dashboard.month_leads_count > 0"  cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
          <v-sheet class="wsRoundedHalf pa-6 fill-height " >
            <div class="d-flex justify-space-between align-center">
              <h4 class="">{{ $t('Leads') }}:</h4>
              <v-btn elevation="0" small  class="noCaps" outlined :color="wsACCENT">Всі курси</v-btn>
            </div>

            <v-simple-table class="mt-5">
              <tbody>
              <tr v-for="(item,i) in leads" :key="i">
                <td>
                  <h5>{{ item.name }}</h5>
                  <h6 class="font-weight-light">{{ item.comment }}</h6>
                </td>
                <td>
                  <h5>{{ item.date }}</h5>
                </td>
              </tr>
              </tbody>
            </v-simple-table>

          </v-sheet>
        </v-col>
        <!-- Month Leads Graphics -->
        <v-col v-if="dashboard.month_leads_count > 0"  cols="12" xs="12" sm="12" md="12" lg="4" xl="4"  >
          <v-sheet class="wsRoundedHalf pa-6 fill-height " max-height="300" min-height="300">
              <div class="d-flex justify-space-between align-center">
                <h4 class="">Заявок за місяць:</h4>
                <v-chip outlined :color="wsACCENT" class="px-4">
                  <h3 >
                    <span v-if="dashboard.month_leads_count > 0">+</span>
                    {{ dashboard.month_leads_count }}
                  </h3>
                </v-chip>
              </div>
              <v-sheet color="transparent"   >
                <apexchart class="ml-n1" type="line" height="230"  :options="chartOptionsLeads" :series="seriesLeads"></apexchart>
              </v-sheet>

          </v-sheet>
        </v-col>
        <!-- No Leads Window -->
        <v-col v-if="dashboard.month_leads_count === 0">

          <v-sheet class="wsRoundedHalf pa-6 fill-height " min-height="300" max-height="300"
                   :color="wsACCENT"  dark>

            <div class="d-flex align-center justify-center fill-height">
              <div>
                <div class="d-flex justify-center">
                  <v-icon size="82">mdi-card-account-phone-outline</v-icon>
                </div>
                <h5 class="font-weight-light text-center mt-3">{{ $t('DashNoMonthLeadsYet') }}</h5>
              </div>
            </div>

          </v-sheet>

        </v-col>

      </v-row>

    </template>


  </dash-page-new>
</template>

<script>
import {mapActions,mapState} from "vuex";
import apexchart from "vue-apexcharts";

import { ONBOARD_DASHBOARD }    from "@/onboardTours/AdminWelcomeWestudy/AdminWelcomeWestudy" ;

export default {
  name: "dashBusinessIndex",
  props: ['alias'],
  components : {
    apexchart
  },
  data() {
    return {

      business : {},
      courses : [],
      leads : [],
      dashboard : {
        courses : []
      },
      defaultDashboardValue : {
        courses : []
      },
      graphicStudentValues : [],
      graphicStudentLabels : [],
      graphicLeadsValues   : [],
      graphicLeadsLabels   : [],

      statisticsLeads : {},
    }
  },
  computed : {
    ...mapState( 'business', [ 'selectedBusiness' ] ),
    seriesStudent() {
      return [{
        name: "Нові реєстрації",
        data: this.graphicStudentValues
      }]
    },
    seriesLeads() {
      return [{
        name: "Нові реєстрації",
        data: this.graphicLeadsValues
      }]
    },
    chartOptionsStudent() {
      return {
        chart: {
          height : 230,
          type: 'line',
          fontFamily : 'Montserrat,Helvetica,Arial,sans-serif',
          selection: {
            enabled: false,
          },
          zoom: {
            enabled: false
          },
          floating : true,
          toolbar : {
            tools: {
              download: false
            }
          },
        },
        colors : [this.wsACCENT],
        dataLabels: {
          enabled: true
        },

        stroke: {
          curve: 'smooth',
          width : 3,
        },

        grid: {
          show : false,
        },
        yaxis : {
          show: false,
          floating : true,
          axisBorder : { show : false },
          axisTicks : { show : false },
          min : 0,
        },
        xaxis: {
          // axisBorder : { show : false },
          // axisTicks : { show : false },
          // floating : true,
          categories: this.graphicStudentLabels,
        }
      }
    },
    chartOptionsLeads() {
      return {
        chart: {
          height : 230,
          type: 'line',
          fontFamily : 'Montserrat,Helvetica,Arial,sans-serif',
          selection: {
            enabled: false,
          },
          zoom: {
            enabled: false
          },
          floating : true,
          toolbar : {
            tools: {
              download: false
            }
          },
        },
        colors : [this.wsACCENT],
        dataLabels: {
          enabled: true
        },

        stroke: {
          curve: 'smooth',
          width : 3,
        },

        grid: {
          show : false,
        },
        yaxis : {
          show: false,
          floating : true,
          axisBorder : { show : false },
          axisTicks : { show : false },
          min : 0,
        },
        xaxis: {
          // axisBorder : { show : false },
          // axisTicks : { show : false },
          // floating : true,
          categories: this.graphicLeadsLabels,
        }
      }
    }
  },
  methods : {
    ...mapActions( 'dashboard', [ 'GET_DASHBOARD_ADMIN_WESTUDY' ] ),

    async initPage() {

      this.dashboard = await this.GET_DASHBOARD_ADMIN_WESTUDY() || {}

      this.courses = this.dashboard.courses
      this.leads   = this.dashboard.leads

      this.graphicStudentValues = this.dashboard.month_students_values
      this.graphicStudentLabels = this.dashboard.month_students_labels

      this.graphicLeadsValues = this.dashboard.month_leads_values
      this.graphicLeadsLabels = this.dashboard.month_leads_labels

      this.statisticsLeads = this.dashboard.statistics_leads
    }
  },
  mounted() {

    this.initPage()
    ONBOARD_DASHBOARD.init(this)

  }
}
</script>

<style scoped>

</style>